import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/mobileIndex',
    name: 'mobile-index',
    component: () => import('../viewsMobile/mobileIndex.vue')
  }
];

const router = createRouter({
  history: createWebHistory(), // createWebHashHistory()
  routes
});

export default router;

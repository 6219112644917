import axios from 'axios';

console.log(window.location);
console.log(process.env.NODE_ENV);
console.log(window.location.protocol + '//' + process.env.VUE_APP_API_URL.split('://')[1]);
// 创建axios实例
const $http = axios.create({
  // 服务接口请求
  baseURL: process.env.NODE_ENV === 'production' ? window.location.protocol + '//' + process.env.VUE_APP_API_URL.split('://')[1] : process.env.VUE_APP_API_URL,
  // 超时设置
  timeout: 5000,
  headers: { 'Content-Type': 'multipart/form-data;charset=utf-8' }
});
console.log(process.env.VUE_APP_API_URL, '222222222');
//请求拦截
$http.interceptors.request.use((config) => {
  console.log('请求拦截', config);
  config.headers = config.headers || {};
  if (localStorage.getItem('token')) {
    config.headers.token = localStorage.getItem('token') || '';
  }
  return config;
});

//响应拦截
$http.interceptors.response.use(
  (res) => {
    console.log('相应拦截', res)
    const statusCode: number = res.status;
    if (statusCode !== 200) {
      return Promise.reject(res.data);
    }
    return res.data;
  },
  (error) => {
    console.log(error);
  }
);

export default $http;

import { createApp } from 'vue';
import Toast from './index.vue';

const showToast = (msg: string, options = { duration: 3000, isMobile: false }) => {
  const { duration, isMobile } = options;
  const div = document.createElement('div');
  const componentInstance = createApp(Toast, {
    show: true,
    msg,
    isMobile,
    duration
  });

  componentInstance.mount(div);
  document.body.appendChild(div);

  const timer: number = window.setTimeout(() => {
    componentInstance.unmount();
    clearTimeout(timer);
    document.body.removeChild(div);
  }, duration);
};

export default showToast;

export const subTitleMsg = {
  overview: {
    title: 'Product Overview',
    desc: ''
  },
  advantages: {
    title: 'Product Advantages',
    desc: 'Tailored for Various Production Lines and Environments'
  },
  efficiency: {
    title: 'Clients',
    desc: 'Partnering with 1000+ enterprises to start the journey of intelligent production.'
  },
  projects: {
    title: 'Projects',
    desc: ''
  },
}
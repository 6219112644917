export default {
  menu: {
    about:'ABOUT US',
    solution:'SOLUTIONS',
    product: 'PRODUCT',
    research: 'RESEARCH',
    news: 'NEWS',
    recruit: 'JOIN US',
    contact: 'CONTACT',
  },
};
import $http from './index';

type User = {
  id: number;
  name: string;
};
interface IApiPromise<T = any> {
  code: number;
  data: T;
  msg: string;
}
export const api = {
  async addContract(formData: any): Promise<IApiPromise> {
    return await $http
      .post('/contact.html?act=addmsg&fe_type=0', formData, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      });
  }
};

<template>
  <div v-if="isShow" class="toast" :class="{'mobile': isMobile}">
    <div class="msg">{{ msg }}</div>
    <div class="sub-msg">We shall contact you soon!</div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue'
const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  msg: {
    type: String,
    default: 'message',
  },
  duration: {
    type: Number,
    default: 1500
  },
  isMobile: {
    type: Boolean,
    default: false
  }
})

const isShow = ref(props.show)
const emit = defineEmits(['update:show'])

watch(() => props.show, (newVal, oldVal) => {
  isShow.value = newVal
  if (newVal) {
    clearInterval(timer)
    var timer = setTimeout(() => {
      isShow.value = false
      emit('update:show', false)
    }, props.duration)
  }
})
</script>

<style scoped lang="scss">
.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  padding: 14px;
  background-color: #00B2E3;
  border-radius: 4px;
  color: #fff;
  font-size: 32px;
  font-family: OPPO SANS B;
  text-align: center;
  &.mobile {
    font-size: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .sub-msg {
      font-size: 14px;
    }
  }
  .sub-msg {
    font-size: 20px;
  }
}
</style>

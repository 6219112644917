export default {
  menu: {
    about:'关于宏工',
    solution:'行业解决方案',
    product: '产品中心',
    research: '研发与制造',
    news: '新闻中心',
    recruit: '人才招聘',
    contact: '联系我们',
  },
};